import { React } from "react";
import { useTranslation } from "react-i18next";

const SectionRoadmap = () => {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="section bg-theme tc-light section-connect"
        id="roadmap"
      >
        <div className="container ov-h">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Roadmap"
            >
              {t("ROADMAP_TITLE")}
            </h2>
          </div>
        </div>

        <div className="nk-block ov-h">
          <div className="container">
            <div
              className="roadmap-list animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <div className="roadmap-item roadmap-item-sm roadmap-done">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_1")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_1")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-done">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_2")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_2")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_3")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_3")}</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_4")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_4")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-sm">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_5")}
                  </h6>
                  <p>Start Token Pre-Sale Round</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_5")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_5")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_6")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_6")}</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_7")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_7")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-sm">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_8")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_8")}</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_9")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_9")}</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_10")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_10")}</p>
                </div>
              </div>

              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    {t("ROADMAP_DATE_11")}
                  </h6>
                  <p>{t("ROADMAP_DETAIL_11")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionRoadmap;
