import React from "react";

// Material components
import { Tabs, Tab, Divider, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import SwipeableViews from "react-swipeable-views";

import styles from "./ClaimingStakingBox.module.css";
import ClaimingForm from "../Staking/ClaimingForm/ClaimingForm";
import StakeForm from "./StakeFrom/StakeForm";
import UnstakForm from "./UnstakeForm/UnstakeForm";

import { useTheme } from "@emotion/react";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
};

const ClaimingStakingBox = ({
  account,
  totalStaked,
  stakingContract,
  tokenContract,
  userBalance,
  myStake,
  apr,
  estimatedReward,
  estimatedLastTimeReward,
  stakingHandler,
  unStakingHandler,
  claimingHandler,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Box className={!isMobile ? styles.main : styles.mainMobile}>
        <Box className={!isMobile ? styles.content : styles.contentMobile}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "rgb(245 241 241 / 10%)",
              paddingTop: "20px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              TabIndicatorProps={{
                style: { backgroundColor: "white" },
              }}
            >
              <Tab label="claiming" sx={{ color: "white" }} />
              <Tab label="Stake" sx={{ color: "white" }} />
              <Tab label="Unstake" sx={{ color: "white" }} />
            </Tabs>
          </Box>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <ClaimingForm
                account={account}
                claimingHandler={claimingHandler}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StakeForm
                userBalance={userBalance}
                apr={apr}
                stakingHandler={stakingHandler}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UnstakForm
                account={account}
                myStake={myStake}
                stakingContract={stakingContract}
                estimatedReward={estimatedReward}
                estimatedLastTimeReward={estimatedLastTimeReward}
                unStakingHandler={unStakingHandler}
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>

      <Divider sx={{ borderBottom: "1px solid", mb: 5 }} />

      <Box
        sx={{
          marginBottom: "300px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 200,
              fontSize: "15px",
              color: "white",
            }}
            variant="body2"
            component="p"
          >
            Total Staked (by All Users) : {totalStaked} (PGNE)
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            marginBottom: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 200,
              fontSize: "15px",
              color: "white",
            }}
            variant="body2"
            component="p"
          >
            PointGenie Staking Contract Address : {stakingContract._address}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            marginBottom: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 200,
              fontSize: "15px",
              color: "white",
            }}
            variant="body2"
            component="p"
          >
            PointGenie Coin Smart Contract Address : {tokenContract._address}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ClaimingStakingBox;
