import { React } from "react";
import GfxZB from "../../assets/front-imgs/light/gfx-z-b.png";
import { useTranslation } from "react-i18next";

const SectionGenieVerse = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section bg-theme-dark-alt tc-light py-0 ov-h">
        <div className="container">
          <div className="nk-block nk-block-features-2">
            <div className="row align-items-center gutter-vr-30px justify-content-center justify-content-md-between">
              <div className="col-mb-9 col-sm-7 col-md-8 col-lg-8">
                <div
                  className="py-4 animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  <img src={GfxZB} alt="gfx" />
                </div>
              </div>
              <div className="col-sm-10 col-md-4 text-center text-md-left">
                <div className="nk-block-text">
                  <h4
                    className="title title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {t("GENIEVERSE_TITLE")}
                  </h4>
                  <p
                    className="lead animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    {t("GENIEVERSE_DETAIL_1")}
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    {t("GENIEVERSE_DETAIL_2")}
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".5"
                  >
                    {t("GENIEVERSE_DETAIL_3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionGenieVerse;
