import { React } from "react";
import { useTranslation } from "react-i18next";

const SectionFaqs = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section bg-theme tc-light pb-0" id="faqs">
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="FAQS"
            >
              {t("FAQ_TITLE")}
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              {t("FAQ_DETAIL")}
            </p>
          </div>

          <div className="nk-block">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <ul
                  className="nav tab-nav tab-nav-line mgb-r animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <li>
                    <a
                      className="active"
                      data-toggle="tab"
                      href="#general-questions-19"
                    >
                      {t("FAQ_GENERAL")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#ico-questions-19">
                      {t("FAQ_ICO")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#tokens-sales-19">
                      {t("FAQ_TOKEN")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#client-19">
                      {t("FAQ_NFT")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#legal-19">
                      {t("FAQ_LEGAL")}
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <div
                    className="tab-pane fade show active"
                    id="general-questions-19"
                  >
                    <div className="accordion accordion-faq" id="faq-67">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-67-1"
                        >
                          {t("FAQ_GENERAL_ASK_1")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-1"
                          className="collapse show"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_GENERAL_ANSWER_1")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-2"
                        >
                          {t("FAQ_GENERAL_ASK_2")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-2"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_GENERAL_ANSWER_2")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-3"
                        >
                          {t("FAQ_GENERAL_ASK_3")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-3"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_GENERAL_ANSWER_3")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-4"
                        >
                          {t("FAQ_GENERAL_ASK_4")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-4"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_GENERAL_ANSWER_4")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="ico-questions-19">
                    <div className="accordion accordion-faq" id="faq-68">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-68-1"
                        >
                          {t("FAQ_ICO_ASK_1")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-1"
                          className="collapse show"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_ICO_ANSWER_1")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-2"
                        >
                          {t("FAQ_ICO_ASK_2")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-2"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_ICO_ANSWER_2")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-3"
                        >
                          {t("FAQ_ICO_ASK_3")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-3"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_ICO_ANSWER_3")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-4"
                        >
                          {t("FAQ_ICO_ASK_4")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-4"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_ICO_ANSWER_4")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tokens-sales-19">
                    <div className="accordion accordion-faq" id="faq-69">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-69-1"
                        >
                          {t("FAQ_TOKEN_ASK_1")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-1"
                          className="collapse show"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_TOKEN_ANSWER_1")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-2"
                        >
                          {t("FAQ_TOKEN_ASK_2")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-2"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_TOKEN_ANSWER_2")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-3"
                        >
                          {t("FAQ_TOKEN_ASK_3")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-3"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_TOKEN_ANSWER_3")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-4"
                        >
                          {t("FAQ_TOKEN_ASK_4")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-4"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_TOKEN_ANSWER_4")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="client-19">
                    <div className="accordion accordion-faq" id="faq-70">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-70-1"
                        >
                          {t("FAQ_NFT_ASK_1")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-1"
                          className="collapse show"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_NFT_ANSWER_1")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-2"
                        >
                          {t("FAQ_NFT_ASK_2")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-2"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_NFT_ANSWER_2")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-3"
                        >
                          {t("FAQ_NFT_ASK_3")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-3"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_NFT_ANSWER_3")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-4"
                        >
                          {t("FAQ_NFT_ASK_4")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-4"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_NFT_ANSWER_4")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="legal-19">
                    <div className="accordion accordion-faq" id="faq-71">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-71-1"
                        >
                          {t("FAQ_LEGAL_ASK_1")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-1"
                          className="collapse show"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_LEGAL_ANSWER_1")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-2"
                        >
                          {t("FAQ_LEGAL_ASK_2")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-2"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_LEGAL_ANSWER_2")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-3"
                        >
                          {t("FAQ_LEGAL_ASK_3")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-3"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_LEGAL_ANSWER_3")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-4"
                        >
                          {t("FAQ_LEGAL_ASK_4")}
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-4"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>{t("FAQ_LEGAL_ANSWER_4")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFaqs;
