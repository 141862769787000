import React, { useState, useEffect } from "react";

import Web3 from "web3";

import TitleBox from "../../components/ClaimingPageComponents/Title/TitleBox";
import ClaimingStakingBox from "../../components/ClaimingPageComponents/Staking/ClaimingStakingBox";

import TestToken from "../../abis/PGenieToken.json";
import TokenStaking from "../../abis/PGenieTokenStaking.json";

const ClaimingPage = ({ darkMode }) => {
  const [account, setAccount] = useState("Connecting to Metamask..");

  const [network, setNetwork] = useState({ id: "0", name: "none" });
  const [tokenContract, setTokenContract] = useState("");
  const [tokenStakingContract, setTokenStakingContract] = useState("");

  const [estimatedReward, setEstimatedReward] = useState(0);
  const [estimatedLastTimeReward, setEstimatedLastTimeReward] = useState(0);

  const [totalStaked, setTotalStaked] = useState(0);
  const [myStake, setMyStake] = useState(0);
  const [appStatus, setAppStatus] = useState(true);
  const [userBalance, setUserBalance] = useState("0");
  const [apr, setApr] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };

    ethEnabled();
  }, []);

  const fetchDataFromBlockchain = async () => {
    //fetching points from api

    if (window.ethereum) {
      // await window.ethereum.send('eth_requestAccounts');
      await window.ethereum.request({ method: "eth_requestAccounts" });
      window.web3 = new Web3(window.ethereum);

      //connecting to metamask
      let web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);

      //loading users network ID and name
      const networkId = await web3.eth.net.getId();
      const networkType = await web3.eth.net.getNetworkType();
      setNetwork({ ...network, id: networkId, name: networkType });

      //loading TestToken contract data
      const testTokenData = TestToken.networks[networkId];
      if (testTokenData) {
        let web3 = window.web3;
        const testToken = new web3.eth.Contract(
          TestToken.abi,
          testTokenData.address
        );

        setTokenContract(testToken);
        //  fetching balance of Testtoken and storing in state
        let testTokenBalance = await testToken.methods
          .balanceOf(accounts[0])
          .call();
        let convertedBalance = window.web3.utils.fromWei(
          testTokenBalance.toString(),
          "Ether"
        );
        setUserBalance(convertedBalance);

        //fetching staking contract balance
        /*const tempBalance = TokenStaking.networks[networkId];
        let stakingContractBallance = await testToken.methods
          .balanceOf(tempBalance.address)
          .call();

        convertedBalance = window.web3.utils.fromWei(
          stakingContractBallance.toString(),
          "Ether"
        );
        console.log("staking contract balance : ", convertedBalance);*/
        //removing initial balance
        //setContractBalance(convertedBalance);*/
      } else {
        setAppStatus(false);
        window.alert(
          "TestToken contract is not deployed on this network, please change to testnet"
        );
      }

      //loading TokenStaking contract data
      const tokenStakingData = TokenStaking.networks[networkId];

      if (tokenStakingData) {
        let web3 = window.web3;
        const tokenStaking = new web3.eth.Contract(
          TokenStaking.abi,
          tokenStakingData.address
        );
        setTokenStakingContract(tokenStaking);

        //  fetching total staked TokenStaking  and storing in state
        let myStake = await tokenStaking.methods
          .stakingBalance(accounts[0])
          .call();

        let convertedBalance = window.web3.utils.fromWei(
          myStake.toString(),
          "Ether"
        );
        setMyStake(convertedBalance);

        //checking totalStaked
        let tempTotalStaked = await tokenStaking.methods
          .getTotalStakedStake()
          .call();
        convertedBalance = window.web3.utils.fromWei(
          tempTotalStaked.toString(),
          "Ether"
        );

        setTotalStaked(convertedBalance);

        //fetching APR values from contract
        let tempAPR = await tokenStaking.methods.percentageBasisPoints().call();
        setApr((tempAPR / 10000) * 100);

        //fetching total estimated Reward and last time estimated reward
        let tempEstimatedReward = await tokenStaking.methods
          .expectedInterest(accounts[0])
          .call();
        convertedBalance = window.web3.utils.fromWei(
          tempEstimatedReward.toString(),
          "Ether"
        );
        setEstimatedReward(convertedBalance);

        let tempEstimatedLastTimeReward = await tokenStaking.methods
          .expectedLastTimeInterest(accounts[0])
          .call();
        convertedBalance = window.web3.utils.fromWei(
          tempEstimatedLastTimeReward.toString(),
          "Ether"
        );
        setEstimatedLastTimeReward(convertedBalance);
      } else {
        setAppStatus(false);
        window.alert(
          "TokenStaking contract is not deployed on this network, please change to testnet"
        );
      }
    } else if (!window.web3) {
      setAppStatus(false);
      setAccount("Metamask is not detected.");
    }
  };

  const stakingHandler = (amount, lockTime) => {
    if (!appStatus) {
    } else {
      let convertToWei = window.web3.utils.toWei(
        window.web3.utils.toBN(amount),
        "Ether"
      );
      tokenContract.methods
        .approve(tokenStakingContract._address, convertToWei)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          tokenStakingContract.methods
            .setTimePeriod(lockTime)
            .send({ from: account })
            .on("transactionHash", (hash) => {
              tokenStakingContract.methods
                .stakeTokens(tokenContract._address, convertToWei)
                .send({ from: account })
                .on("transactionHash", (hash) => {
                  fetchDataFromBlockchain();
                })
                .on("receipt", (receipt) => {
                  fetchDataFromBlockchain();
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  fetchDataFromBlockchain();
                })
                .on("error", function (error) {
                  console.log("stake error", error);
                });
            })
            .on("error", function (error) {
              console.log("setTime period error", error);
              //console.log("Error Code:", error.code);
              //console.log(error.message);
            });
        })
        .on("error", function (error) {
          console.log("approve fail", error);
          //console.log("Error Code:", error.code);
          //console.log(error.message);
        });
    }
  };

  const unStakingHandler = () => {
    if (!appStatus) {
    } else {
      tokenStakingContract.methods
        .unstakeAllTokensAndWithdrawInterestEarned(tokenContract._address)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          fetchDataFromBlockchain();
        })
        .on("receipt", (receipt) => {
          fetchDataFromBlockchain();
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          fetchDataFromBlockchain();
        })
        .on("error", function (error) {
          //console.log("Error Code:", error);
          //console.log(error.message);
        });
    }
  };

  const claimingHandler = (points) => {
    let tokenAmount = points / 10;

    let convertToWei = window.web3.utils.toWei(
      window.web3.utils.toBN(tokenAmount),
      "Ether"
    );

    tokenContract.methods
      .claimTokensFromPoints(account, convertToWei)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        console.log("sucess");
      })
      .on("error", function (error) {
        console.log("transaction fail", error);
      });

    /*tokenContract.methods
      .approve(account, convertToWei)
      .send({ from: account })
      .on("transactionHash", (hash) => {})
      .on("error", function (error) {
        console.log("approve fail", error);
      });*/
  };

  return (
    <>
      <main className="nk-pages">
        <div className="container">
          <div className="nk-block nk-block-text-wrap">
            {/* Section of title */}
            <TitleBox
              account={account}
              apr={apr}
              dailyEarning={Math.round((apr / 365) * 100) / 100}
            />
            <ClaimingStakingBox
              account={account}
              totalStaked={totalStaked}
              stakingContract={tokenStakingContract}
              tokenContract={tokenContract}
              userBalance={userBalance}
              myStake={myStake}
              apr={apr}
              estimatedReward={estimatedReward}
              estimatedLastTimeReward={estimatedLastTimeReward}
              stakingHandler={stakingHandler}
              unStakingHandler={unStakingHandler}
              claimingHandler={claimingHandler}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default ClaimingPage;
