import { React } from "react";
import EDarkImg from "../../assets/front-imgs/dark/e-dark.png";
import FDarkImg from "../../assets/front-imgs/dark/f-dark.png";
import { useTranslation } from "react-i18next";

const SectionToken = () => {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="section section-tokensale tc-light bg-theme-dark"
        id="token"
      >
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="TOKEN"
            >
              {t("TOKEN_SALE_TITLE")}
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              {t("TOKEN_SALE_DETAIL")}
            </p>
          </div>
          <div className="nk-block nk-block-token mgb-m30">
            <div className="row align-items-center justify-content-between gutter-vr-50px">
              <div className="col-lg-6">
                <div className="row gutter-vr-30px">
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_1")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_1")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".4"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_2")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_2")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_3")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_3")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".6"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_4")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_4")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_5")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_5")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKEN_SALE_SUBTITLE_6")}
                      </h6>
                      <p>{t("TOKEN_SALE_SUBDETAIL_6")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div
                  className="token-status token-status-s5 bg-theme-alt round no-bd animated"
                  data-animate="fadeInUp"
                  data-delay=".9"
                >
                  <div className="token-box token-box-s3">
                    <div
                      className="countdown-s3 countdown-s4 countdown"
                      data-date="2021/08/10"
                    ></div>
                  </div>
                  <div className="token-action">
                    <a
                      className="btn btn-md btn-grad btn-grad-alternet btn-round"
                      href="/#"
                    >
                      {t("BTN_JOIN_BY_TOKEN")}
                    </a>
                  </div>
                  <ul className="icon-list">
                    <li>
                      <em className="fab fa-bitcoin"></em>
                    </li>
                    <li>
                      <em className="fas fa-won-sign"></em>
                    </li>
                    <li>
                      <em className="fab fa-cc-visa"></em>
                    </li>
                    <li>
                      <em className="fab fa-cc-mastercard"></em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-head text-center wide-auto-sm mt-5">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
            >
              {t("TOKENOMIC_TITLE")}
            </h2>
          </div>

          <div className="nk-block nk-block-token mgb-m30">
            <div className="row align-items-center justify-content-between gutter-vr-50px">
              <div className="col-lg-12">
                <div className="row gutter-vr-30px">
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_1")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_1")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".4"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_2")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_2")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_3")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_3")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".6"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_4")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_4")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_5")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_5")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_6")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_6")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_7")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_7")}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        {t("TOKENOMIC_SUBTITLE_8")}
                      </h6>
                      <p>{t("TOKENOMIC_DETAIL_8")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div className="row justify-content-center gutter-vr-40px">
              <div className="col-md-6 col-mb-8">
                <div className="single-chart">
                  <h3
                    className="mgb-l text-center title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {t("TOKEN_SALE_SUBTITLE_7")}
                  </h3>
                  <div
                    className="chart animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    <img src={EDarkImg} alt="chart" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-mb-8">
                <div className="single-chart">
                  <h3
                    className="mgb-l text-center title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    {t("TOKEN_SALE_SUBTITLE_8")}
                  </h3>
                  <div
                    className="chart animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <img src={FDarkImg} alt="chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionToken;
