import React, { useState } from "react";

// Material components
import {
  Typography,
  MenuItem,
  Menu,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";

import { useConfirmDialog } from "react-mui-confirm";

// Gradient buttons
import { GradientButtonThird } from "../../../../Utils/GradientButtons/GradientButtons";
import { useTheme } from "@emotion/react";
import { BsChevronDown } from "react-icons/bs";

//import StakeIcon from "../../../../assets/images/stake.png";

const StakeForm = ({ userBalance, apr, stakingHandler }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [lockTimeLabel, setLockTimeLabel] = useState("7 Days");
  const [lockTime, setLockTime] = useState(7);
  const [compooundDailyInterest, setCompoundDailyInterest] = useState(0);
  const [amountToStake, setAmountToStake] = useState(0);
  const [aprForPeriod, setAprForPeriod] = useState(1.73);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const confirm = useConfirmDialog();

  const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLockTime = (_lockTimeLabel, _lockTime) => {
    setLockTimeLabel(_lockTimeLabel);
    setLockTime(_lockTime);
    setAprForPeriod(Math.round((apr / 365) * _lockTime * 100) / 100);
    setAnchorEl(null);
    handleCompundInterest(_lockTime);
  };

  const handleCompundInterest = (_lockTime) => {
    let tempAmount = parseInt(amountToStake);
    let dailyInterest = 0;
    for (let i = 0; i < _lockTime; i++) {
      tempAmount += dailyInterest;
      dailyInterest = (tempAmount * apr) / 100 / 365;
    }
    setCompoundDailyInterest(tempAmount);
  };

  const handleInputChange = (e) => {
    setAmountToStake(e.target.value);
  };

  const handleStakeConfirm = (_amountToStake, _lockTime) => {
    confirm({
      title: "Are you sure you want to stake?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => stakingHandler(_amountToStake, _lockTime),
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Available to Stake
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {userBalance} (PGNE)
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "0px 20px 10px 20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <TextField
          variant="standard" // <== changed this
          label="Amount to Stake (PGNE)"
          type="number"
          value={amountToStake}
          onChange={(e) => handleInputChange(e)}
          InputProps={{ color: "blue" }}
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          margin="normal"
          fullWidth
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Estimated Coins with Earnings
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {compooundDailyInterest}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Lock Time
        </Typography>
        <Typography
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          sx={{
            mb: 2,
            mt: 0.5,
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          {lockTimeLabel}
          <Typography sx={{ marginLeft: "80%", cursor: "pointer" }}>
            <BsChevronDown onClick={handleClickTrigger} />
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          APR
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {aprForPeriod}%
        </Typography>
      </Box>

      <Box>
        <GradientButtonThird
          sx={{
            marginTop: "20px",
            marginBottom: "16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            width: "100%",
            height: "91px",
          }}
          onClick={() => handleStakeConfirm(amountToStake, lockTime)}
        >
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              fontSize: "24px",
            }}
          >
            Stake
          </Typography>
        </GradientButtonThird>
      </Box>

      <Menu
        id="basic-menu"
        PaperProps={{
          style: {
            backgroundColor: "#171c26",
            padding: "0 10px",
            borderRadius: "10px",
          },
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ marginTop: "12px" }}
      >
        <MenuItem
          style={{
            width: "200px",
            backgroundColor: "#040404",
            paddingLeft: "2rem",
            gap: 15,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          onClick={() => handleLockTime("7 Days", 7)}
        >
          <Typography color="#ffffff">7 Days</Typography>
        </MenuItem>
        <MenuItem
          style={{
            width: "200px",
            backgroundColor: "#040404",
            paddingLeft: "2rem",
            gap: 15,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          onClick={() => handleLockTime("14 Days", 14)}
        >
          <Typography color="#ffffff">14 Days</Typography>
        </MenuItem>
        <MenuItem
          style={{
            width: "200px",
            backgroundColor: "#040404",
            paddingLeft: "2rem",
            gap: 15,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          onClick={() => handleLockTime("1 Month", 30)}
        >
          <Typography color="#ffffff">1 Month</Typography>
        </MenuItem>
        <MenuItem
          style={{
            width: "200px",
            backgroundColor: "#040404",
            paddingLeft: "2rem",
            gap: 15,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          onClick={() => handleLockTime("6 Months", 180)}
        >
          <Typography color="#ffffff">6 Months</Typography>
        </MenuItem>
        <MenuItem
          style={{
            width: "200px",
            backgroundColor: "#040404",
            paddingLeft: "2rem",
            gap: 15,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          onClick={() => handleLockTime("1 Year", 365)}
        >
          <Typography color="#ffffff">1 Year</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default StakeForm;
