import { React, useState, useEffect } from "react";

// Material components
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useConfirmDialog } from "react-mui-confirm";
import axios from "axios";

// Gradient buttons
import { GradientButtonThird } from "../../../../Utils/GradientButtons/GradientButtons";
import PointImg from "../../../../assets/front-imgs/PointgenieCoin.png";

import { useTheme } from "@emotion/react";

const ClaimingForm = ({ account, claimingHandler }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const confirm = useConfirmDialog();

  //const [authToken, setAuthToken] = useState(null);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    const ethEnabled = async () => {
      getPointsFromBackend();
    };

    ethEnabled();
  }, []);

  const getPointsFromBackend = async () => {
    const tokenHeaderConfig = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const resToken = await axios.post(
      "https://www.pointgenie.com/api/firebase.php",
      {
        email: "ericazcona@gmail.com",
        password: "Planetavenus",
      },
      {
        headers: tokenHeaderConfig,
      }
    );
    let bearToken = resToken.data.id_token;
    //setAuthToken(resToken.data.id_token);

    const getPointsHeaderConfig = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${bearToken}`,
    };
    const resGetPoints = await axios.post(
      "https://www.pointgenie.com/api/user.php?method=total_points",
      {},
      {
        headers: getPointsHeaderConfig,
      }
    );

    setPoints(resGetPoints.data.total_points);
  };

  const onConfirmClaiming = (points) => {
    claimingHandler(points);
  };
  const handleClaimingConfirm = (points) => {
    confirm({
      title: "Are you sure you want to claim ?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => onConfirmClaiming(points),
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Total GeniePoints Earned
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: 2,
            mt: 0.5,
            color: "white",
            fontSize: "15px",
          }}
        >
          {points}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          PointGenie Coins awarded
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: 2,
            mt: 0.5,
            color: "white",
            fontSize: "15px",
          }}
        >
          {points / 10} Point Genie Coin (PGNE)
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Your Public Wallet Address
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: 2,
            mt: 0.5,
            color: "white",
            fontSize: "15px",
          }}
        >
          {account}
        </Typography>
      </Box>
      <Box>
        <GradientButtonThird
          sx={{
            marginTop: "20px",
            marginBottom: "16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            width: "100%",
            height: "91px",
          }}
          onClick={() => handleClaimingConfirm(points)}
        >
          <img
            style={{ width: "50px", height: "50px" }}
            src={PointImg}
            alt="Point Icon"
          />
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              fontSize: "24px",
            }}
          >
            Claim
          </Typography>
        </GradientButtonThird>
      </Box>
    </>
  );
};

export default ClaimingForm;
