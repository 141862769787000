import { React, useState } from "react";
import Logo from "../../assets/front-imgs/logo-white.png";
import LogoSet from "../../assets/front-imgs/logo-white2x.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const [lang, setLang] = useState("sn");

  const changeLang = () => {
    i18next.changeLanguage(lang);
    if (lang == "en") setLang("sn");
    else setLang("en");
  };

  return (
    <>
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        <div className="header-main">
          <div className="header-container container">
            <div className="header-wrap">
              <div
                className="header-logo logo animated"
                data-animate="fadeInDown"
                data-delay=".65"
                style={!isMobile ? { marginLeft: "-30px" } : {}}
              >
                <a href="/" className="logo-link">
                  <img
                    className="logo-light"
                    src={Logo}
                    srcSet={LogoSet}
                    alt="logo"
                    style={{ width: "200px", height: "30px" }}
                  />
                </a>
              </div>

              <div className="header-nav-toggle">
                <a
                  href={0}
                  className="navbar-toggle"
                  data-menu-toggle="example-menu-04"
                >
                  <div className="toggle-line">
                    <span></span>
                  </div>
                </a>
              </div>

              <div className="header-navbar header-navbar-s1">
                <nav className="header-menu" id="example-menu-04">
                  <ul
                    className="menu menu-s2 animated"
                    data-animate="fadeInDown"
                    data-delay=".75"
                    style={{ marginTop: "-5px" }}
                  >
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#token">
                        {t("NAV_TOKEN_SALE")}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#roadmap">
                        {t("NAV_ROADMAP")}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#team">
                        {t("NAV_TEAM")}
                      </a>
                    </li>

                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#faqs">
                        {t("NAV_FAQ")}
                      </a>
                    </li>
                    {/*<li className="menu-item">
                      <a className="menu-link nav-link" href="/claiming">
                        {t("NAV_DAPP")}
                      </a>
  </li>*/}
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#contact">
                        {t("NAV_CONTACT")}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        className="menu-link nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={changeLang}
                      >
                        {t("NAV_LANG")}
                      </a>
                    </li>
                  </ul>
                  <ul
                    className="menu-btns animated"
                    data-animate="fadeInDown"
                    data-delay=".85"
                  >
                    <li>
                      <a
                        href="GenieCoinWhitepaper.pdf"
                        className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"
                      >
                        <span>{t("NAV_WHITEPAPER")}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://pointgenie.page.link/pointgeniecoin"
                        className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"
                      >
                        <span>{t("NAV_TRY_APP")}</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
