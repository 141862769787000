import { React } from "react";
import CellPhoneImg from "../../assets/front-imgs/cell-phone.png";
import { useTranslation } from "react-i18next";

const SectionMeetPointGenieApp = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section bg-theme-dark-alt tc-light">
        <div className="container">
          <div className="section-head text-center wide-auto-sm tc-light">
            <div className="section-head-line">
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
              <span className="line-4"></span>
              <span className="line-5"></span>
              <span className="line-6"></span>
              <span className="line-7"></span>
              <span className="line-8"></span>
            </div>
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="APPS"
            >
              {t("MEET_POINT_GENIE_APP_TITLE")}
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              {t("MEET_POINT_GENIE_APP_DETAIL_1")}
            </p>
          </div>
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-8">
                <div
                  className="nk-block-img animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <img src={CellPhoneImg} alt="app" />
                </div>
              </div>
              <div className="col-sm-10 col-md-4 col-lg-4">
                <div className="nk-block-text ml-lg-3 ml-xl-0">
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    {t("MEET_POINT_GENIE_APP_DETAIL_2")}
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    {t("MEET_POINT_GENIE_APP_DETAIL_3")}
                  </p>

                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    {t("MEET_POINT_GENIE_APP_DETAIL_4")}
                  </p>
                  <ul className="list list-dot pdb-r">
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      {t("MEET_POINT_GENIE_APP_LIST_ITEM_1")}
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".55"
                    >
                      {t("MEET_POINT_GENIE_APP_LIST_ITEM_2")}
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".65"
                    >
                      {t("MEET_POINT_GENIE_APP_LIST_ITEM_3")}
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      {t("MEET_POINT_GENIE_APP_LIST_ITEM_4")}
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".75"
                    >
                      {t("MEET_POINT_GENIE_APP_LIST_ITEM_5")}
                    </li>
                  </ul>
                  <ul className="btn-grp justify-content-center justify-content-md-start">
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".85"
                    >
                      <a
                        href="https://pointgenie.page.link/pointgeniecoin"
                        className="btn btn-grad btn-round btn-md"
                      >
                        {t("BTN_GET_THE_APP_NOW")}
                      </a>
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".95"
                    >
                      <ul className="btn-grp gutter-15px">
                        <li>
                          <a href="https://pointgenie.page.link/pointgeniecoin">
                            <em className="fab fa-apple"></em>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionMeetPointGenieApp;
