import { React } from "react";
import { useTranslation } from "react-i18next";

const SectionContact = () => {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="section section-contact bg-theme tc-light"
        id="contact"
      >
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Contact"
            >
              {t("CONTACT_TITLE")}
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              {t("CONTACT_DETAIL")}
            </p>
          </div>
          <div className="nk-block block-contact">
            <div className="row justify-content-center text-center">
              <div className="col-xl-8 col-lg-10">
                <ul className="contact-list contact-list-s1 flex-wrap flex-md-nowrap pdb-l">
                  <li
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    <em className="contact-icon fas fa-phone"></em>
                    <div className="contact-text">
                      <span>1(305)771-1959</span>
                    </div>
                  </li>
                  <li
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <em className="contact-icon fas fa-envelope"></em>
                    <div className="contact-text">
                      <span>support@pointgenie.com</span>
                    </div>
                  </li>
                  <li
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".5"
                  >
                    <em className="contact-icon fas fa-paper-plane"></em>
                    <div className="contact-text">
                      <span>{t("CONTACT_TG")}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-xl-8 col-lg-10">
                <div className="contact-wrap p-0">
                  <form
                    id="contact-form-01"
                    className="contact-form nk-form-submit"
                    action="form/contact.php"
                    method="post"
                  >
                    <div
                      className="field-item field-item-center animated"
                      data-animate="fadeInUp"
                      data-delay=".6"
                    >
                      <input
                        name="contact-name"
                        type="text"
                        className="input-line required"
                      />
                      <label className="field-label field-label-line">
                        {t("CONTACT_NAME")}
                      </label>
                    </div>
                    <div
                      className="field-item field-item-center animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <input
                        name="contact-email"
                        type="email"
                        className="input-line input-line-center required email"
                      />
                      <label className="field-label field-label-line">
                        {t("CONTACT_EMAIL")}
                      </label>
                    </div>
                    <div
                      className="field-item field-item-center animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <textarea
                        name="contact-message"
                        className="input-line input-line-center input-textarea required"
                      ></textarea>
                      <label className="field-label field-label-line">
                        {t("CONTACT_MESSAGE")}
                      </label>
                    </div>
                    <input
                      type="text"
                      className="d-none"
                      name="form-anti-honeypot"
                    />
                    <div
                      className="field-wrap animated"
                      data-animate="fadeInUp"
                      data-delay=".9"
                    >
                      <button
                        type="submit"
                        className="btn btn-round btn-lg btn-grad"
                      >
                        {t("BTN_SUBMIT")}
                      </button>
                      <div className="form-results"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionContact;
