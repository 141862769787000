import React, { useState, useEffect } from "react";

// Material components
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useConfirmDialog } from "react-mui-confirm";

// Gradient buttons
import { GradientButtonThird } from "../../../../Utils/GradientButtons/GradientButtons";
import { useTheme } from "@emotion/react";

//import UnstakeIcon from "../../../../assets/images/unstake.png";

const UnstakeForm = ({
  account,
  myStake,
  stakingContract,
  estimatedReward,
  estimatedLastTimeReward,
  unStakingHandler,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const confirm = useConfirmDialog();

  const [estimatedCoinToReceive, setEstimatedCoinToReceive] = useState(0);

  useEffect(() => {
    const ethEnabled = async () => {
      getStakingStatus();
    };

    ethEnabled();
  }, []);

  const getStakingStatus = async () => {
    //checking totalStaked
    let initialTime = await stakingContract.methods
      .initialStakingTimestamp(account)
      .call();
    let timePeriod = await stakingContract.methods.timePeriod(account).call();
    let currentTime = await stakingContract.methods.getCurrentTime().call();

    currentTime = parseInt(currentTime);
    timePeriod = parseInt(timePeriod);
    initialTime = parseInt(initialTime);

    if (initialTime < currentTime && currentTime < initialTime + 86400) {
      let estimatedCoin = myStake * 0.9;
      setEstimatedCoinToReceive(estimatedCoin);
      console.log("test1", estimatedCoin);
    } else if (
      initialTime < currentTime &&
      currentTime > initialTime + 86400 &&
      currentTime < initialTime + timePeriod * 86400
    ) {
      let estimatedCoin =
        parseInt(myStake) +
        Math.round(parseFloat(estimatedReward) * 100 * 0.6) / 100;
      setEstimatedCoinToReceive(estimatedCoin);
      console.log("test2", estimatedCoin);
    } else if (currentTime > initialTime + timePeriod * 86400) {
      let estimatedCoin =
        parseInt(myStake) + Math.round(parseFloat(estimatedReward) * 100) / 100;
      setEstimatedCoinToReceive(estimatedCoin);
      console.log("test3", estimatedCoin);
    } else {
      setEstimatedCoinToReceive(0);
    }
  };
  const handleUnstakeConfirm = () => {
    confirm({
      title: "Are you sure you want to unstake early?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => unStakingHandler(),
    });
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Staked Amount
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {myStake} (PGNE)
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Amount to Unstake
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {myStake} (PGNE)
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Early Unstaking Penalty 40 % of earnings
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {Math.round(parseFloat(estimatedLastTimeReward) * 0.4 * 100) / 100}{" "}
          (PGNE)
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Estimated Coins to receive
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {estimatedCoinToReceive} (PGNE)
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#0A0C36",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 200,
            fontSize: "15px",
            color: "white",
            marginBottom: !isMobile ? "23px" : "10px",
          }}
          variant="body2"
          component="p"
        >
          Your Public Wallet Address
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 2, mt: 0.5, color: "white" }}
        >
          {account}
        </Typography>
      </Box>
      <Box>
        <GradientButtonThird
          sx={{
            marginTop: "20px",
            marginBottom: "16px",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            width: "100%",
            height: "91px",
          }}
        >
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              fontSize: "24px",
            }}
            onClick={() => handleUnstakeConfirm()}
          >
            Unstake
          </Typography>
        </GradientButtonThird>
      </Box>
    </>
  );
};

export default UnstakeForm;
